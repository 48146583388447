import React from 'react';
import Header from "../header/header";
import Footer from "../Footer/Footer";
import { Layout } from "antd";
import { router } from "../AppRouter/AppRouter";
import { RouterProvider } from "react-router-dom";
import classes from './AppContent.module.scss';
import Preloader from "../Preloader/Preloader";

const AppContent = () => {
	return (
		<Layout className={classes.layoutContainer}>
			<Preloader />

			<Header />
			<Layout>
				<RouterProvider router={router} />
			</Layout>
			<Footer />
		</Layout>
	);
};

export default AppContent;
