import React, {useEffect, useState} from 'react';
import AppContent from "./components/AppContent/AppContent";
import {ConfigProvider} from "antd";
import { theme } from "./constants/theme";

const App = () => {

	return (
		<ConfigProvider wave={{ showEffect: (_) => null }} theme={theme}>
			<AppContent />
		</ConfigProvider>
	);
};

export default App;
