import React from 'react';
import classes from './Footer.module.scss';
import {Button, Col, Form, Grid, Row, Typography} from "antd";
import {combineClasses} from "../../utils/helpers";
import {Input} from "../_UI";
import {THEME} from "../../constants/styles";
import AnimatedTypography from "../_UI/AnimatedTypography/AnimatedTypography";
import AnimationRotationComponent from "../_UI/AnimatedRotationComponent/AnimationRotationComponent";

const CredentialsBlock = ({label, className, children}) => (
	<div className={combineClasses(classes.credentialsContainer, className)}>
		<AnimatedTypography.Text className={classes.labelText}>{label}</AnimatedTypography.Text>
		<AnimatedTypography.Paragraph style={{ height: 30 }}>
			<Typography.Text className={classes.valueText}>{children}</Typography.Text>
		</AnimatedTypography.Paragraph>
	</div>
)
const Footer = () => {
	const [form] = Form.useForm();
	const breakpoints = Grid.useBreakpoint();

	return (
		<div id="footer" className={classes.container}>
			<Row>
				<Col className={classes.columnContainer} xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 1 }}>
					<div className="w100">
						<AnimatedTypography.Paragraph className={classes.subTitle}>
							Свяжитесь с нами
						</AnimatedTypography.Paragraph>
						<AnimatedTypography.Title className={classes.title} level={2}>
							Контактная информация
						</AnimatedTypography.Title>
						<CredentialsBlock label="Коммерческий директор">
							+7 (910) 098-80-01
						</CredentialsBlock>
						<CredentialsBlock label="E-mail">
							info@pls33.ru
						</CredentialsBlock>
						<CredentialsBlock label="Адрес производства">
							Владимирская обл., г. Ковров, ул. 3 Интернационала, 32 с2
						</CredentialsBlock>
					</div>
				</Col>
				<Col className={classes.columnContainer} xs={{ span: 24, order: 3 }} lg={{ span: 12, order: 2 }}>
					<div className="w100">
						<Form form={form}>
							<Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="name">
								<Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.LIGHT} placeholder="Ваше имя"/>
							</Form.Item>
							<Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="contacts">
								<Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.LIGHT} placeholder="E-mail / Телефон"/>
							</Form.Item>
							<Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="message">
								<Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.LIGHT} placeholder="Сообщение"/>
							</Form.Item>
							<div className={classes.formFooter}>
								<Typography.Paragraph className={classes.formFooter__Text}>
									Нажимая на кнопку, вы подтверждаете согласие на обработку персональных данных
								</Typography.Paragraph>
								<Button onClick={form.submit} size={breakpoints.md ? 'middle' : 'small'} className={classes.formFooter__Btn} block={!breakpoints.md}>Отправить</Button>
							</div>
						</Form>
					</div>
				</Col>
				<Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 3 }}>
					<div className={classes.columnContainer}>
						<div className="w100">
							<AnimatedTypography.Paragraph className={classes.subTitle}>
								Мнформация о компании
							</AnimatedTypography.Paragraph>
							<AnimatedTypography.Title className={classes.title} level={2}>
								Реквизиты
							</AnimatedTypography.Title>
							<Row justify="space-between" align="middle">
								<CredentialsBlock label="Наименование компании">
									ООО «Палисандр»
								</CredentialsBlock>
								<CredentialsBlock label="ИНН">
									3305798661
								</CredentialsBlock>
								<CredentialsBlock label="ОГРН">
									1183328006366
								</CredentialsBlock>
							</Row>
						</div>
					</div>
					{breakpoints.lg && (
						<div className={classes.columnCopyright}>
							<Typography.Text className={classes.copyRightText}>© ООО «Палисандр» 2023. Все права защищены.</Typography.Text>
						</div>
					)}
				</Col>
				<Col className={classes.columnImageBackground} xs={{ span: 24, order: 4 }} lg={{ span: 12, order: 4 }}>
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d36262.412796945966!2d41.26229247510268!3d56.350502894360936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414c4855078e6497%3A0x803064d60cfb450d!2z0YPQuy4gMyDQmNC90YLQtdGA0L3QsNGG0LjQvtC90LDQu9CwLCAzMiwg0YEyLCDQmtC-0LLRgNC-0LIsINCS0LvQsNC00LjQvNC40YDRgdC60LDRjyDQvtCx0LsuLCDQoNC-0YHRgdC40Y8sIDYwMTkwMQ!5e0!3m2!1sru!2slv!4v1696084520443!5m2!1sru!2slv"
						width="100%" height="100%" allowFullScreen="" loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"></iframe>
				</Col>
				<Col xs={{ span: 24, order: 5 }} lg={{ span: 0, order: 5 }}>
					<div className={classes.columnCopyright}>
						<Typography.Text className={combineClasses(classes.copyRightText, 'w100')} style={{ display: 'flex', justifyContent: 'center' }}>© ООО «Палисандр» 2023. Все права защищены.</Typography.Text>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Footer;
