import {createBrowserRouter} from "react-router-dom";
import MainPage from "../../pages/MainPage/MainPage";

export const ROUTES = Object.freeze({
	MAIN: '/',
})

export const router = createBrowserRouter([
	{
		path: ROUTES.MAIN,
		element: <MainPage/>,
	},
]);
