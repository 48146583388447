import React from 'react';
import './Preloader.css';
import {combineClasses} from "../../utils/helpers";

const Preloader = () => {
    return (
        <div className="preloader">
            <div className="loaderInside"/>
            <div className="loaderBlock">
                <div className="loaderText">
                    <span className="letter-1">З</span>
                    <span className="letter-2">А</span>
                    <span className="letter-3">Г</span>
                    <span className="letter-4">Р</span>
                    <span className="letter-5">У</span>
                    <span className="letter-6">З</span>
                    <span className="letter-7">К</span>
                    <span className="letter-8">А</span>
                    <span className="letter-9">.</span>
                    <span className="letter-10">.</span>
                    <span className="letter-11">.</span>
                </div>
            </div>
        </div>
    )
}
export default Preloader;
