import React from 'react';
import BannerRow from "./sections/BannerRow/BannerRow";
import BenefitsRow from "./sections/BenefitsRow/BenefitsRow";
import ProjectsRow from "./sections/ProjectsRow/ProjectsRow";
import SchemeRow from "./sections/SchemeRow/SchemeRow";
import ContactsUsRow from "./sections/ContactUsRow/ContactsUsRow";
import AboutRow from "./sections/AboutRow/AboutRow";
import DesignersRow from "./sections/DesignersRow/DesignersRow";
import ServicesRow from "./sections/ServicesRow/ServicesRow";

const MainPage = () => {
	return (
		<div>
			<BannerRow/>
			<BenefitsRow/>
			<ProjectsRow/>
			<SchemeRow/>
			<ContactsUsRow/>
			<AboutRow/>
			<DesignersRow/>
			<ServicesRow/>
		</div>
	);
};

export default MainPage;
