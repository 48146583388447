import React, {useState} from 'react';
import classes from './AboutRow.module.scss';
import {Button, Col, Grid, Image, Row, Typography} from "antd";
import chair from "../../../../assets/images/chair.png";
import {play} from "../../../../assets/images/svg/play";
import Icon from "@ant-design/icons";
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";
import VideoModal from "../../../../components/VideoModal/VideoModal";

const AboutRow = () => {
	const [videoOpen, setVideoOpen] = useState();

	const breakpoints = Grid.useBreakpoint();

	const openVideoModal = () => setVideoOpen(true);
	const closeVideoModal = () => setVideoOpen(false);

	return (
		<section id="about" className={classes.container}>
			<Row justify="space-between">
				<Col xs={24} xl={17} className={classes.contentContainer}>
					<AnimatedTypography.Paragraph className={classes.subTitle}>
						Более 20 лет мы меняем жизни наших клиентов
					</AnimatedTypography.Paragraph>
					<AnimatedTypography.Title className={classes.title} level={3}>
						Создаём уникальную мебель и интерьеры, воплощая любые пожелания наших клиентов. Для разработки проекта привлекаем опытных дизайнеров.
					</AnimatedTypography.Title>
					<AnimatedTypography.Paragraph className={classes.description}>
					Уделяем особое внимание каждой детали и изготавливаем мебель высокого качества, которая прослужит вам долгие годы. Мы понимаем, что мебель – не просто предмет для использования, а неотъемлемая часть вашего дома, отражающая вашу индивидуальность и стиль. Поэтому мы придаём особое значение сотрудничеству с каждым клиентом, чтобы учесть все их пожелания и создать мебель, идеально соответствующую ожиданиям.
					</AnimatedTypography.Paragraph>

					<Image className={classes.image} src={chair} preview={false}/>
				</Col>
				<Col xs={0} xl={7} className={classes.imageContainer}>
					<div className={classes.darkOverlay}>
						<div className={classes.imageTextContainer}>
							<Typography.Title className={classes.imageTitle} level={3}>
								Посмотрите как мы создаем вашу мебель
							</Typography.Title>
							<Button onClick={openVideoModal} className={classes.btn} size={breakpoints.md ? 'middle' : 'small'} block>
								<div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center' }}>
									Посмотреть видео <Icon component={play} />
								</div>
							</Button>
						</div>
					</div>
				</Col>
			</Row>

			<VideoModal width={800} height={500} onCancel={closeVideoModal} open={videoOpen}/>
		</section>
	);
};

export default AboutRow;
