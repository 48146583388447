import React, { useEffect } from 'react';
import classes from './SendModal.module.scss';
import './SendModal.css';
import { Modal, Image, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import sideImage from '../../assets/webp/send-background.webp';
import SendForm from "../SendForm/SendForm";

const SendModal = ({ open, onCancel, ...props }) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [open]);

    return (
        <Modal zIndex={1005} style={{ top: 20, bottom: 20 }} width={800} closeIcon={<CloseOutlined style={{ color: 'white' }} />} className="send-modal-custom" onCancel={onCancel} footer={null} open={open} centered destroyOnClose {...props}>
            <Image src={sideImage} className={classes.image} preview={false} width="100%" />
            <div className={classes.contentContainer}>
                <Typography.Title level={3} className={classes.title}>Оставляйте заявку, и мы с вами свяжемся</Typography.Title>
                <SendForm />
            </div>
        </Modal>
    );
};

export default SendModal;
