import React from 'react';
import classes from './SchemeRow.module.scss';
import {Col, Image, Row, Typography} from "antd";
import SchemeList from "../../../../components/SchemeList/SchemeList";
import computerIcon from '../../../../assets/images/computer.png';
import rulerIcon from '../../../../assets/images/ruler.png';
import drawingIcon from '../../../../assets/images/drawing-tablet.png';
import furnitureIcon from '../../../../assets/images/furniture.png';
import truckIcon from '../../../../assets/images/truck.png';
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";

const schemePoints = [
	{key: 0, title: 'Оставьте заявку на сайте или позвоните нам', image: computerIcon},
	{key: 1, title: 'Выезд замерщика на объект для замеров', image: rulerIcon},
	{key: 2, title: 'Разработка дизайн-проекта, чертежей, визуализация', image: drawingIcon},
	{key: 3, title: 'Подписание договора и производство мебели', image: furnitureIcon},
	{key: 4, title: 'Доставка собственным транспортом и сборка', image: truckIcon},
]

const SchemeRow = () => {
	return (
		<section id="scheme" className={classes.container}>
			<div className={classes.contentContainer}>
				<AnimatedTypography.Paragraph className={classes.subTitle}>
					5 шагов к мебели вашей мечты
				</AnimatedTypography.Paragraph>
				<AnimatedTypography.Title className={classes.title} level={2}>
					Схема нашей работы
				</AnimatedTypography.Title>
			</div>
			<Row justify="space-between">
				<Col xs={24} xl={17}>
					<SchemeList data={schemePoints} />
				</Col>
				<Col xs={0} xl={7} className={classes.imageContainer}/>
			</Row>
		</section>
	);
};

export default SchemeRow;
