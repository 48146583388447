import React, {useState} from 'react';
import classes from "./BenefitsRow.module.scss";
import {Button, Col, Collapse, Grid, Row, Typography} from "antd";
import AnimatedComponent from "../../../../components/_UI/AnimatedComponent/AnimatedComponent";
import Icon from "@ant-design/icons";
import {plusIcon} from "../../../../assets/images/svg/plus-icon";
import SendModal from "../../../../components/SendModal/SendModal";

const benefits = [
	{key: 0, label: 'Собственное производство', desc: 'Мы уверены в качестве нашей мебели, потому что изготавливаем её сами'},
	{key: 1, label: 'На рынке с 2000 года', desc: 'За это время создали множество непохожих друг на друга проектов и приобрели колоссальный опыт в работе с индивидуальными проектами'},
	{key: 2, label: 'Комплекс услуг в одном месте', desc: 'Выезд на замер с подбором материалов на месте, создание визуализации, изготовление на собственном производстве, доставка и сборка мебели'},
	{key: 3, label: 'Гарантия до 12 месяцев', desc: 'Мы полностью уверены в качестве нашей мебели, поэтому предоставляем гарантию до 12 месяцев'},
	{key: 4, label: 'Скидка на повторный заказ', desc: 'Уже заказывали мебель у нас? Сделайте новый заказ с выгодой'},
	{key: 5, label: 'Нам доверяют', desc: '99% клиентов обращаются к нам повторно благодаря высокому качеству оказываемых услуг'},
]
const BenefitsRow = () => {
	const [modalVisible, setModalVisible] = useState(false);

	const breakpoints = Grid.useBreakpoint();

	return (
		<section id="benefits" className={classes.container}>
			<div className={classes.innerContainer}>
				<Row justify="space-between" className={classes.introduction} align="bottom">
					<Col md={14} xs={24}>
						<AnimatedComponent>
							<Typography.Paragraph className={classes.subTitle}>
								Вы получаете больше, чем просто изготовление мебели
							</Typography.Paragraph>
							<Typography.Title className={classes.title} level={3}>
							Мы специализируемся на изготовлении не только шкафов и кухонных гарнитуров. У нас вы можете заказать любую мебель для вашего интерьера: прихожие, комоды, гардеробные, прикроватные модули, тумбы, а также интерьерные панели с отделкой из ценных пород древесины.							</Typography.Title>
						</AnimatedComponent>
					</Col>
					<Col md={8} xs={24}>
						<AnimatedComponent>
							<Typography.Paragraph className={classes.description}>
							В рамках каждого проекта наша команда проводит замеры вашего помещения, создает детальную визуализацию проекта, изготавливает мебель индивидуально под ваше помещение и осуществляем её доставку и сборку.<br/><br/>
							Принимаем заказы на изготовление мебели и интерьерных панелей. Мы стремимся создать гармоничные и уникальные интерьеры, в которых каждый элемент составляет завершенное и сбалансированное целое.
							</Typography.Paragraph>
							<Button onClick={() => setModalVisible(true)} className={classes.orderManButton} size={breakpoints.lg ? 'middle' : 'small'} block={!breakpoints.md}>
								Вызвать замерщика
							</Button>
						</AnimatedComponent>
					</Col>
				</Row>
				<Row className={classes.benefitsContainer}>
					{breakpoints.md ? benefits.map(i => (
						<Col xs={24} md={10} lg={7}>
							<AnimatedComponent key={i.key}>
								<div className={classes.container}>
									<div className={classes.header}>
										<Icon className={classes.plusIcon} component={plusIcon}/>
										<div className={classes.strokeLine}/>
									</div>
									<div className={classes.contentContainer}>
										<Typography.Title className={classes.title} level={4}>{i.label}</Typography.Title>
										<Typography.Paragraph className={classes.description}>
											{i.desc}
										</Typography.Paragraph>
									</div>
								</div>
							</AnimatedComponent>
						</Col>
					)) : (
						<Collapse size="middle" expandIconPosition="end" expandIcon={() => <div><Icon className={classes.plusIcon} component={plusIcon}/></div>} className="w100" items={benefits.map(i => ({ key: i.key, label: <Typography.Title className={classes.accordionTitle} level={5}>{i.label}</Typography.Title>, children: <Typography.Paragraph className={classes.accordionDesc}>{i.desc}</Typography.Paragraph> }))} bordered={false} ghost accordion />
					)}
				</Row>
			</div>

			<SendModal open={modalVisible} onCancel={() => setModalVisible(false)}/>
		</section>
	);
};

export default BenefitsRow;
