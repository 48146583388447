import React from 'react';
import classes from "./AnimationRotationComponent.module.scss";
import PropTypes from "prop-types";
import {combineClasses} from "../../../utils/helpers";

const AnimationRotationComponent = ({component: Component = 'div', rootClassName, children, ...props}) => {
	return (
		<Component className={combineClasses(classes.clip, rootClassName)} {...props}>
			<div className={classes.buttonText}>
				{children}
			</div>
			<div className={combineClasses(classes.buttonText, classes.buttonTextBottom)}>
				{children}
			</div>
		</Component>
	);
};
AnimationRotationComponent.propTypes = {
	component: PropTypes.node,
	rootClassName: PropTypes.string,
	children: PropTypes.any
}
export default AnimationRotationComponent;
