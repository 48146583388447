import { theme as AntTheme } from "antd";

export const theme = Object.freeze({
	token: {
		fontFamily: "Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
		colorBgLayout: "#ffffff",
		colorBgContainer: '#f6ffed',
		motion: false,
	},
	components: {
		Typography: {
			fontSize: 16,
			fontSizeHeading1: 60,
			fontSizeHeading2: 40,
			fontSizeHeading3: 31,
			fontSizeHeading4: 18,
			titleMarginTop: 0,
			colorLink: "rgb(0, 0, 0)",
			colorLinkHover: "rgb(112, 112, 112)",
			colorLinkActive: "rgb(0, 0, 0)"
		},
		Button: {
			borderRadius: 0,
			borderRadiusLG: 0,
			borderRadiusSM: 0,
			contentFontSize: 18,
			contentFontSizeSM: 13,
			controlHeight: 61,
			controlHeightSM: 45,
			paddingContentHorizontal: 40,
			primaryShadow: "",
			defaultShadow: "",
			dangerShadow: "",
		},
		Input: {
			colorBgContainer: "rgba(255, 255, 255, 0)",
			colorBgContainerDisabled: "rgba(255, 255, 255, 0)",
			colorBorder: "rgba(235, 235, 235, 0.2)",
			colorError: "rgb(255, 138, 138)",
			colorErrorBorderHover: "rgb(255, 138, 138)",
			colorErrorOutline: "rgba(255, 38, 5, 0)",
			controlOutline: "rgba(5, 145, 255, 0)",
			borderRadius: 0,
			borderRadiusLG: 0,
			borderRadiusSM: 0,
			fontFamily: "Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
			controlHeight: 36.5,
			controlHeightLG: 111,
			controlHeightSM: 12,
			controlOutlineWidth: 0,
			controlPaddingHorizontal: 0,
			controlPaddingHorizontalSM: 0,
			fontSizeIcon: 22,
			fontSizeLG: 14,
			fontSizeSM: 12,
			paddingLG: 0,
			paddingSM: 0,
			paddingXS: 0,
			paddingXXS: 0,
			colorTextPlaceholder: "rgb(118, 123, 130)",
			lineWidth: 0
		}
	}
});

// Light 300
// Regular 400 (Rubik)
// Medium 500
// SemiBold 600
// Bold 700
// ExtraBold 800
// Black 900
