import React, { useRef, useState } from 'react';
import classes from './ProjectsRow.module.scss';
import { Button, Carousel, Grid, Image, Typography } from "antd";
import project11 from '../../../../assets/webp/project-image1.webp';
import project12 from '../../../../assets/webp/project-image2.webp';
import project13 from '../../../../assets/webp/project-image3.webp';
import project21 from '../../../../assets/webp/project21.webp'
import project22 from '../../../../assets/webp/project22.webp'
import project23 from '../../../../assets/webp/project23.webp'
import project31 from '../../../../assets/webp/project31.webp'
import project32 from '../../../../assets/webp/project32.webp'
import { COLORS } from "../../../../constants/styles";
import Icon from "@ant-design/icons";
import { arrowRight } from "../../../../assets/images/svg/arrow-right";
import { combineClasses } from "../../../../utils/helpers";

const projects = [
	{ key: 0, title: 'Проект на Таганке', description: 'Квартира для молодой семейной пары', city: 'г. Москва', size: 'Общая площадь - 62 кв.м.', image: [project11, project12, project13] },
	{ key: 1, title: 'Проект на Боровском шоссе', description: 'Квартира для семьи с двумя детьми', city: 'г. Москва', size: 'Общая площадь - 69 кв.м.', image: [project31, project32] },
	{ key: 2, title: 'Проект на 1-я Тверской-Ямской', description: 'Организация офисного пространства для IT-компании', city: 'г. Москва', size: 'Общая площадь - 100 кв.м.', image: [project21, project22, project23] },
]

const test = [
	project31, project32
]

const ProjectsRow = () => {
	const [currentSlide, setCurrentSlideIndex] = useState(0);
	const carouselRef = useRef(null);
	const innerCarouselRef = useRef(null);

	const breakpoints = Grid.useBreakpoint();
	console.log(innerCarouselRef)

	return (
		<section id="projects" className={classes.container}>
			<Carousel ref={carouselRef} afterChange={setCurrentSlideIndex} effect="fade" dots={false}>
				{Object.values(projects).map((project, index) => {
					const totalProjects = Object.keys(projects).length;
					return (
						<div key={project.key}>
							<div className={classes.projectSlide}>
								<div className={classes.darkOverlay}>
									<div className={classes.innerCarousel}>
										<Carousel className={classes.carousel} ref={innerCarouselRef} autoplay dots={false}>
											{project.image.map((item) => (
												<div className={classes.innerSlide} key={item}>
													<Image src={item} preview={false} width='100%' height='100%' />
												</div>
											))}
										</Carousel>
									</div>
									<div className={classes.innerContainer}>
										<div className={classes.column1}>
											<div className={classes.titleContainer}>
												<Typography.Title level={2} className={classes.projectTitle}>{project.title}</Typography.Title>
											</div>
											<div className={classes.projectDescriptionContainer}>
												{breakpoints.lg ? (
													<>
														<div className={classes.descriptionItem}>
															<Typography.Text className={classes.descriptionText}>{project.description}</Typography.Text>
														</div>
														<div className={classes.descriptionItem}>
															<Typography.Text className={classes.descriptionText}>{project.city}</Typography.Text>
														</div>
														<div className={classes.descriptionItem}>
															<Typography.Text className={classes.descriptionText}>{project.size}</Typography.Text>
														</div>
													</>
												) : (
													<div className={classes.descriptionItem}>
														<div onClick={() => carouselRef.current.next()} style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 10 }}>
															<Typography.Text className={classes.titleText}><Typography.Text className={classes.titleText} style={{ color: COLORS.POSITIVE }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Typography.Text>/{totalProjects < 10 ? `0${totalProjects}` : totalProjects}</Typography.Text>
															<Icon component={arrowRight} />
														</div>
													</div>
												)}
											</div>
										</div>
										<div onClick={() => carouselRef.current.next()} className={classes.column2}>
											{breakpoints.lg && (
												<div className={classes.previewTitle}>
													<Typography.Text className={classes.titleText}><Typography.Text className={classes.titleText} style={{ color: COLORS.POSITIVE }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Typography.Text>/{totalProjects < 10 ? `0${totalProjects}` : totalProjects}</Typography.Text>
													<Typography.Link className={classes.titleText} onClick={() => carouselRef.current.next()}>Следующий проект <Icon component={arrowRight} /></Typography.Link>
												</div>
											)}
											<div className={classes.previewImageContainer} >
												<Image src={projects[(index + 1) % projects.length].image[0]} rootClassName={classes.previewImageContainer} height="100%" width="100%" preview={false} />
											</div>
											<div className={combineClasses(classes.descriptionItem, classes.grayBackground)} style={{ flexGrow: 0 }}>
												<Typography.Text className={classes.descriptionText}>Следующий проект</Typography.Text>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</Carousel>
		</section>
	);
};

export default ProjectsRow;
