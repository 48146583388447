export const combineClasses = (...classesAndConditions) => {
	const combinedClasses = [];

	classesAndConditions.forEach(item => {
		if (Array.isArray(item)) {
			const [className, condition] = item;
			if (condition) {
				combinedClasses.push(className);
			}
		} else if (typeof item === 'string') {
			combinedClasses.push(item);
		}
	});

	return combinedClasses.join(' ');
}

export const renderFontSizes = () => {
	const windowWidth = window.innerWidth;
	let fontSizeMultiplier = 1;

	if (windowWidth < 576) {
		fontSizeMultiplier = 0.3;
	} else if (windowWidth < 768) {
		fontSizeMultiplier = 0.6;
	} else if (windowWidth < 992) {
		fontSizeMultiplier = 0.7;
	} else if (windowWidth < 1200) {
		fontSizeMultiplier = 0.9;
	} else if (windowWidth < 1400) {
		fontSizeMultiplier = 1;
	}

	return {
		fontSize: 16 * fontSizeMultiplier,
		fontSizeHeading1: 60 * fontSizeMultiplier,
		fontSizeHeading2: 40 * fontSizeMultiplier,
		fontSizeHeading3: 31 * fontSizeMultiplier,
		fontSizeHeading4: 18 * fontSizeMultiplier,
	};
};
