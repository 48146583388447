import React from 'react';
import {ConfigProvider, Input as AntInput} from 'antd';
import classes from './Input.module.scss';
import PropTypes from "prop-types";
import {THEME} from "../../../constants/styles";
import {combineClasses} from "../../../utils/helpers";

const Input = ({ theme, ...props }) => {
	if (theme === THEME.LIGHT) return (
		<ConfigProvider
			theme={{
				components: {
					Input: {
						colorText: "rgb(0, 0, 0)",
						colorPrimaryHover: "rgb(0, 0, 0)",
						colorPrimaryActive: "rgb(0, 0, 0)",
						colorPrimary: "rgb(0, 0, 0)",
						colorTextDisabled: "rgba(0, 0, 0, 0.25)"
					}
				}
			}}
		>
			<AntInput rootClassName={combineClasses(classes.input, classes.lightContainer)} {...props} />
		</ConfigProvider>
	)
	return (
		<ConfigProvider
			theme={{
				components: {
					Input: {
						colorText: "rgb(255, 255, 255)",
						colorPrimaryHover: "rgb(255, 255, 255)",
						colorPrimaryActive: "rgb(255, 255, 255)",
						colorPrimary: "rgb(255, 255, 255)",
						colorTextDisabled: "rgba(255, 255, 255, 0.25)"
					}
				}
			}}
		>
			<AntInput rootClassName={combineClasses(classes.input, classes.darkContainer)} {...props} />
		</ConfigProvider>
	);
};
Input.propTypes = {
	...AntInput.propTypes,
	theme: PropTypes.oneOf([THEME.LIGHT, THEME.DARK]),
}
Input.defaultProps = {
	theme: THEME.LIGHT,
}
export default Input;
