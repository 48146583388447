import React, {useEffect, useState} from 'react';
import classes from './Header.module.scss';
import {Image, Typography, Grid, Modal, ConfigProvider} from "antd";
import {logoLarge} from '../../assets/images/svg/logo_large';
import {logoSmall} from '../../assets/images/svg/logo_small';
import {combineClasses} from "../../utils/helpers";
import Icon from "@ant-design/icons";
import {hamburgerMenu} from "../../assets/images/svg/hamburger-menu";
import {logoMobile} from "../../assets/images/svg/logo_mobile";
import {cross} from "../../assets/images/svg/cross";
import {REQUISITES} from "../../constants/requisites";
import {theme} from "../../constants/theme";
import BurgerIcon from "../BurgerIcon/BurgerIcon";
import AnimationRotationComponent from "../_UI/AnimatedRotationComponent/AnimationRotationComponent";

const navItems = [
	{ key: 'furniture', anchor: 'benefits', title: 'Индивидуальная мебель' },
	{ key: 'projects', anchor: 'projects', title: 'Проекты' },
	{ key: 'about', anchor: 'about', title: 'О компании' },
	{ key: 'designers', anchor: 'designers', title: 'Дизайнеры' },
	{ key: 'contacts', anchor: 'footer', title: 'Контакты' },
]

const Header = () => {
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [headerVisible, setHeaderVisible] = useState(true);
	const [headerMobileVisible, setHeaderMobileVisible] = useState(false);
	const [passed100vh, setPassed100vh] = useState(false);

	const breakpoints = Grid.useBreakpoint();

	const toggleMenu = () => {
		setHeaderMobileVisible((prevMenuOpen) => {
			if (!prevMenuOpen) {
				document.body.style.overflow = 'hidden';
				// setHeaderVisible(false);
			} else {
				document.body.style.overflow = 'auto';
				setHeaderVisible(true);
			}
			return !prevMenuOpen;
		});
	}
	useEffect(() => {
		const burgerIn = document.getElementById('burger-in');
		if (headerMobileVisible && burgerIn) {
			burgerIn?.classList.add('transformed'); // Добавить класс 'transformed'
		} else {
			burgerIn?.classList.remove('transformed'); // Удалить класс 'transformed'
		}
	}, [headerMobileVisible]); // Зависимость от headerMobileVisible

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			const isScrollingUp = currentScrollPos < prevScrollPos;

			setPrevScrollPos(currentScrollPos);

			if (currentScrollPos === 0 || isScrollingUp) {
				setHeaderVisible(true);
			} else {
				setHeaderVisible(false);
			}

			if (currentScrollPos > window.innerHeight / 3) {
				setPassed100vh(true);
			} else {
				setPassed100vh(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos]);

	return (
		<div>
			<div className={combineClasses(classes.container, headerVisible ? classes.visible : classes.hidden, passed100vh && classes.blackBackground)}>
				<div className={classes.innerContainer}>
					<Icon component={breakpoints.lg ? logoLarge : logoSmall}/>

					<div className={classes.navContainer}>
						{breakpoints.md ? (
							<ul className={classes.navItems}>
								{navItems.map(nav => (
									<li key={nav.key}>
										<AnimationRotationComponent component={Typography.Link} href={`#${nav.anchor}`}>
											<Typography.Text className={classes.navItem}>{nav.title}</Typography.Text>
										</AnimationRotationComponent>
									</li>
								))}
							</ul>
						) : (
							<></>
							// <div id="burger-out" className="burger" onClick={toggleMenu}>
							// 	<div />
							// 	<div />
							// 	<div />
							// </div>
						)}
					</div>
				</div>
			</div>
			{headerMobileVisible && (
				<div className={classes.mobileMenu__overlay}>
					<div className={classes.mobileMenu__container}>
						<div className={classes.mobileMenu__header}>
							<Icon style={{ marginRight: 20 }} component={logoMobile} />
							{/*<div id="burger-in" className="burger" onClick={toggleMenu}>*/}
							{/*	<div />*/}
							{/*	<div />*/}
							{/*	<div />*/}
							{/*</div>*/}
						</div>
						<div className={classes.mobileMenu__requisitesRow}>
							<div className={classes.mobileMenu__requisitesRow_column}>
								<Typography.Text className={classes.requisitesRow__column_title}>{REQUISITES.PHONE}</Typography.Text>
							</div>
							<div className={classes.mobileMenu__requisitesRow_column}>
								<Typography.Text className={classes.requisitesRow__column_title}>{REQUISITES.EMAIL}</Typography.Text>
							</div>
						</div>
						{navItems.map((nav, index) => (
							<div key={nav.key} className={classes.mobileMenu__navItem}>
								<Typography.Link href={`#${nav.anchor}`} onClick={toggleMenu} className={classes.mobileMenu__navItem_title}>{nav.title}</Typography.Link>
							</div>
						))}
					</div>
				</div>
			)}

			{!breakpoints.md && (
				<div onClick={toggleMenu} className={combineClasses(classes.burgerIconWrapper, headerVisible ? classes.visible : classes.hidden, headerMobileVisible && classes.mobile)}>
					<BurgerIcon isOpen={headerMobileVisible}/>
				</div>
			)}
		</div>
	);
};

export default Header;
