export const COLORS = Object.freeze({
	WHITE: '#FFFFFF',
	BLACK: '#000',
	LIGHT_BLACK: '#272727',
	HAIRLINE: '#EBEBEB',
	BORDER: 'rgba(235, 235, 235, 0.20)',
	PLACEHOLDER: '#767B82',
	POSITIVE: '#AFC538',
	ERROR: '#FF8A8A',
})

export const THEME = Object.freeze({
	LIGHT: 'light',
	DARK: 'dark',
});

// @media (max-width: 1400px) {
// }
// @media (max-width: 1200px) {
// }
// @media (max-width: 992px) {
// }
// @media (max-width: 768px) {
// }
// @media (max-width: 481px) {
// }
// @media (max-width: 321px) {
// }
