import React, { useEffect, useRef } from 'react';
import { Modal } from "antd";
import classes from './VideoModal.module.scss';
import './VideoModal.css';

const VideoModal = ({ open, onCancel, ...props }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (open && videoRef.current) {
            videoRef.current.src = `https://www.youtube.com/embed/UySFn4BLVH4?autoplay=1`;
        } else if (videoRef.current) {
            videoRef.current.src = '';
        }
    }, [open]);

    return (
        <Modal zIndex={1005} width={1000} className="modal-custom" onCancel={onCancel} footer={null} open={open} centered {...props}>
            <iframe
                ref={videoRef}
                width="100%"
                height="100%"
                src=""
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>
            </iframe>
        </Modal>
    );
};

export default VideoModal;
