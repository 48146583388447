import classes from "./ServicesRow.module.scss";
import {Button, Carousel, Col, Collapse, Grid, Image, Row, Typography} from "antd";
import dresser from "../../../../assets/images/dresser.png";
import React from "react";
import Icon from "@ant-design/icons";
import {plusIcon} from "../../../../assets/images/svg/plus-icon";
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";
import AnimatedComponent from "../../../../components/_UI/AnimatedComponent/AnimatedComponent";

const services = [
    {key: 0, label: 'Замер', desc: 'Входит в стоимость при заказе проекта'},
    {key: 1, label: 'Доставка', desc: 'Бесплатная для г. Ковров. Владимирская область – 15 руб./км от производства до объекта. Для Москвы – 13 000 руб. в пределах МКАД'},
    {key: 2, label: 'Сборка и установка', desc: '12% от стоимости мебели'},
    {key: 3, label: 'Гарантия на мебель', desc: 'Гарантийный период действует в течение 12 месяцев с момента завершения сборки мебели'},
    {key: 4, label: 'Сроки и оплата', desc: 'Выполняем заказы от 30 до 45 рабочих дней. Работаем по предоплате 50%'},
]
const ServicesRow = () => {
    const breakpoints = Grid.useBreakpoint();

    return (
        <section id="services" className={classes.container}>
            <div className={classes.innerContainer}>
                <Row className={classes.firstRowContainer} gutter={[0, 40]}>
                    <Col xs={{ order: 2, span: 24 }} sm={{ order: 1, span: 12 }} className={classes.titleContainer}>
                        <AnimatedTypography.Paragraph className={classes.subTitle}>
                            Предлагаем больше, чем просто изготовление мебели
                        </AnimatedTypography.Paragraph>
                        <AnimatedTypography.Title className={classes.title} level={2}>
                            Помимо изготовления мы предлагаем целый комплекс услуг
                        </AnimatedTypography.Title>
                    </Col>
                    <Col xs={{ order: 1, span: 24 }} sm={{ order: 2, span: 3 }}>
                        <Image src={dresser} preview={false} />
                    </Col>
                </Row>
                <Row className={classes.benefitsRow}>
                    {breakpoints.md ? services.map(i => (
                        <Col xs={24} md={10} lg={7}>
                            <AnimatedComponent key={i.key}>
                                <div className={classes.container}>
                                    <div className={classes.header}>
                                        <Icon className={classes.plusIcon} component={plusIcon}/>
                                        <div className={classes.strokeLine}/>
                                    </div>
                                    <div className={classes.contentContainer}>
                                        <Typography.Title className={classes.title} level={4}>{i.label}</Typography.Title>
                                        <Typography.Paragraph className={classes.description}>
                                            {i.desc}
                                        </Typography.Paragraph>
                                    </div>
                                </div>
                            </AnimatedComponent>
                        </Col>
                    )) : (
                        <Collapse size="middle" expandIconPosition="end" expandIcon={() => <div><Icon className={classes.plusIcon} component={plusIcon}/></div>} className="w100" items={services.map(i => ({ key: i.key, label: <Typography.Title className={classes.accordionTitle} level={5}>{i.label}</Typography.Title>, children: <Typography.Paragraph className={classes.accordionDesc}>{i.desc}</Typography.Paragraph> }))} bordered={false} ghost accordion />
                    )}
                    <Col xs={24} md={10} lg={7}>
                        <AnimatedComponent>
                            <Typography.Paragraph className={classes.description}>
                                Дополнительную информацию уточняйте у менеджеров при заказе
                            </Typography.Paragraph>
                            <a href="#footer">
                                <Button className={classes.askBtn} size={breakpoints.md ? 'middle' : 'small'} block>
                                    Задать вопрос
                                </Button>
                            </a>
                        </AnimatedComponent>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default ServicesRow;
