import React from 'react';
import classes from './BurgetIcon.module.scss';
import {combineClasses} from "../../utils/helpers";

const BurgerIcon = ({ isOpen, onClick }) => {
    return (
        <div className={combineClasses(classes.burger, isOpen && classes.transformed)} onClick={onClick}>
            <div />
            <div />
            <div />
        </div>
    );
};

export default BurgerIcon;
