import classes from "./DesignersRow.module.scss";
import { Carousel, Col, Grid, Image, Row, Typography } from "antd";
import designer1 from "../../../../assets/webp/designer1.webp";
import designer2 from "../../../../assets/webp/designer2.webp";
import designer3 from "../../../../assets/webp/designer3.webp";
import React from "react";
import { arrowUp } from "../../../../assets/images/svg/arrow-up";
import Icon from "@ant-design/icons";
import video from '../../../../assets/video/Video-Placeholder.mp4';
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";
import AnimatedComponent from "../../../../components/_UI/AnimatedComponent/AnimatedComponent";

const designers = [
    { key: 0, name: 'Анна Зуева', desc: 'Обладательница Гран-при и звания «Дизайнер года»', imageUrl: designer1, url: 'https://annazueva.design/' },
    { key: 1, name: 'Карина Неделяй', desc: 'Руководитель BAZILIK interior design', imageUrl: designer2, url: 'http://nedeliai.tilda.ws/' },
    { key: 2, name: 'Светлана Халина', desc: 'Руководитель дизайн-студии Sahalina Design', imageUrl: designer3, url: 'https://sahalinadesign.ru/' },
];
const DesignersRow = () => {
    const breakpoints = Grid.useBreakpoint();

    return (
        <section id="designers" className={classes.container}>
            <div className={classes.contentContainer}>
                <AnimatedTypography.Paragraph className={classes.subTitle}>
                    В вашем проекте примут участие профессионалы
                </AnimatedTypography.Paragraph>
                <AnimatedTypography.Title className={classes.title} level={2}>
                    Сотрудничаем с лучшими дизайнерами Москвы, чтобы вы получили эстетичную и удобную мебель
                </AnimatedTypography.Title>
            </div>
            <AnimatedComponent>
                {breakpoints.md ? (
                    <Row justify="space-evenly">
                        {designers.map(i => (
                            <Col span={8} key={i.key}>
                                <div className={classes.designerCard}>
                                    <div className={classes.imageContainer}>
                                        <Image src={i.imageUrl} width="100%" height="100%" object-fit="cover" preview={false} />
                                    </div>
                                    <div className={classes.nameContainer}>
                                        <Typography.Paragraph className={classes.subTitle}>
                                            {i.desc}
                                        </Typography.Paragraph>
                                        <Typography.Title className={classes.title} level={3}>
                                            {i.name}
                                        </Typography.Title>
                                    </div>
                                    <div className={classes.btnContainer}>
                                        <Typography.Text className={classes.text}>
                                            Сайт дизайнера
                                        </Typography.Text>
                                        <Icon className={classes.btnIcon} component={arrowUp} />
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Carousel dots={false} autoplay>
                        {designers.map(i => (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', objectFit: 'cover' }}>
                                    <div className={classes.designerCard}>
                                        <div className={classes.imageContainer}>
                                            <Image src={i.imageUrl} width="100%" height="100%" preview={false} />
                                        </div>
                                        <div className={classes.nameContainer}>
                                            <Typography.Paragraph className={classes.subTitle}>
                                                {i.desc}
                                            </Typography.Paragraph>
                                            <Typography.Title className={classes.title} level={3}>
                                                {i.name}
                                            </Typography.Title>
                                        </div>
                                        <div className={classes.btnContainer} onClick={() => window.open(i.url, '_blank')}>
                                            <Typography.Text className={classes.text}>
                                                Сайт дизайнера
                                            </Typography.Text>
                                            <Icon className={classes.btnIcon} component={arrowUp} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                )}
            </AnimatedComponent>
            {/* <div className={classes.videoSection}>
                <video controls>
                    <source src={video} type="video/mp4" />
                </video>
            </div> */}
        </section>
    );
};

export default DesignersRow;
