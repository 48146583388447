import React, {useState} from 'react';
import classes from './BannerRow.module.scss';
import {Button, Grid, Typography} from "antd";
import {REQUISITES} from "../../../../constants/requisites";
import AnimatedTypography from "../../../../components/_UI/AnimatedTypography/AnimatedTypography";
import AnimatedComponent from "../../../../components/_UI/AnimatedComponent/AnimatedComponent";
import {combineClasses} from "../../../../utils/helpers";
import SendModal from "../../../../components/SendModal/SendModal";

const BannerRow = () => {
	const [modalVisible, setModalVisible] = useState(false);

	const breakpoints = Grid.useBreakpoint();

	return (
		<section id="banner" className={classes.container}>
			<div className={classes.darkOverlay}>
				<div className={classes.innerContainer}>
					<div className={classes.bannerRow}>
						<AnimatedTypography.Title className={classes.title}>
						Изготовление мебели<br/>
							по индивидуальным проектам
						</AnimatedTypography.Title>
						<AnimatedTypography.Paragraph className={classes.subTitle}>
							Профессиональные дизайнеры создадут мебель по любым вашим пожеланиям
						</AnimatedTypography.Paragraph>
						<AnimatedComponent>
							<Button onClick={() => setModalVisible(true)} className={classes.orderManButton} size={breakpoints.md ? 'middle' : 'small'} block={!breakpoints.md}>
								Вызвать замерщика
							</Button>
						</AnimatedComponent>

					</div>
					<div className={classes.requisitesRow}>
						<div className={classes.requisiteItem}>
							<Typography.Text className={classes.requisiteText}>Дизайн-проект бесплатно</Typography.Text>
						</div>
						<div className={classes.requisiteItem}>
							<Typography.Text className={classes.requisiteText}>Изготовление мебели в течение 45 дней</Typography.Text>
						</div>
						<div className={classes.requisiteItem}>
							<Typography.Text className={classes.requisiteText}>Гарантия на мебель - 1 год</Typography.Text>
						</div>
						{breakpoints.md && (
							<>
								<div className={combineClasses(classes.requisiteItem, classes.grayBackground)}>
									<Typography.Text className={classes.requisiteText}>{REQUISITES.PHONE}</Typography.Text>
								</div>
								<div className={combineClasses(classes.requisiteItem, classes.grayBackground)}>
									<Typography.Text className={classes.requisiteText}>{REQUISITES.EMAIL}</Typography.Text>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			<SendModal open={modalVisible} onCancel={() => setModalVisible(false)}/>
		</section>
	);
};

export default BannerRow;
