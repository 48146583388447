import React from 'react';
import {useInView} from "react-intersection-observer";
import {Typography} from "antd";
import classes from './AnimatedTypography.module.scss';
import {combineClasses} from "../../../utils/helpers";

const AnimatedTypography = ({ children, ...props }) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	return (
		<div
			ref={ref}
			className={combineClasses(classes.animatedTypography, inView && classes.visible)}
		>
			<Typography {...props}>{children}</Typography>
		</div>
	);
};

const AnimatedTitle = props => <AnimatedTypography {...props} component={Typography.Title} />;
const AnimatedParagraph = props => <AnimatedTypography {...props} component={Typography.Paragraph} />;
const AnimatedLink = props => <AnimatedTypography {...props} component={Typography.Link} />;
const AnimatedText = props => <AnimatedTypography {...props} component={Typography.Text} />;

AnimatedTypography.Title = AnimatedTitle;
AnimatedTypography.Paragraph = AnimatedParagraph;
AnimatedTypography.Link = AnimatedLink;
AnimatedTypography.Text = AnimatedText;

export default AnimatedTypography;
