import {useInView} from "react-intersection-observer";
import {combineClasses} from "../../../utils/helpers";
import classes from "./AnimatedComponent.module.scss";
import React from "react";

const AnimatedComponent = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div
            ref={ref}
            className={combineClasses(classes.animated, inView && classes.visible)}
        >
            {children}
        </div>
    );
};
export default AnimatedComponent;
