import React from 'react';
import {Button, Form, Grid, Typography, Upload} from "antd";
import {Input} from "../_UI";
import {THEME} from "../../constants/styles";
import classes from "./SendForm.module.scss";

const SendForm = (props) => {
    const [form] = Form.useForm();
    const breakpoints = Grid.useBreakpoint();

    const project = Form.useWatch('project', form);

    const customRequest = ({ file, onSuccess }) => {
        onSuccess();
    };
    return (
        <Form form={form} {...props}>
            <Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="name">
                <Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.DARK} placeholder="Ваше имя"/>
            </Form.Item>
            <Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="contacts">
                <Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.DARK} placeholder="E-mail / Телефон"/>
            </Form.Item>
            {/* <Form.Item rules={[{required: true, message: 'Обязательное поле'}]} name="addInfo"> */}
            <Form.Item>
                <Input size={breakpoints.md ? 'middle' : 'small'} theme={THEME.DARK} placeholder="Дополнительная информация"/>
            </Form.Item>
            <div style={{ marginBottom: 44 }}>
                <Input value={project?.file?.name} size={breakpoints.md ? 'middle' : 'small'} theme={THEME.DARK} placeholder="Прикрепите файл с вашим проектом" suffix={
                    // <Form.Item style={{ marginBottom: 0 }} rules={[{required: true, message: 'Обязательное поле'}]} name="project">
                    <Form.Item style={{ marginBottom: 0 }} name="project">    
                        <Upload customRequest={customRequest} showUploadList={false} maxCount={1} multiple={false}>
                            <Button size={breakpoints.md ? 'large' : 'small'} className={classes.selectFile_btn}>{'Выбрать файл'}</Button>
                        </Upload>
                    </Form.Item>
                } disabled />
            </div>

            <div className={classes.formFooter}>
                <Typography.Paragraph className={classes.formFooter__Text}>
                    Нажимая на кнопку, вы подтверждаете согласие на обработку персональных данных
                </Typography.Paragraph>
                <Button onClick={form.submit} size={breakpoints.md ? 'middle' : 'small'} className={classes.formFooter__Btn} block={!breakpoints.md}>Отправить</Button>
            </div>
        </Form>
    );
};

export default SendForm;
