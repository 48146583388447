import React, { useState } from 'react';
import classes from './SchemeList.module.scss';
import { Image, Typography } from "antd";
import AnimatedTypography from "../_UI/AnimatedTypography/AnimatedTypography";
import AnimationRotationComponent from "../_UI/AnimatedRotationComponent/AnimationRotationComponent";
import AnimatedComponent from "../_UI/AnimatedComponent/AnimatedComponent";
import SendModal from "../../components/SendModal/SendModal";

const SchemeList = ({ data }) => {
	const [modalVisible, setModalVisible] = useState(false);
	return (
		<div className={classes.container}>
			{data.map((i, index) => (
				<AnimatedComponent>
					<div key={index} className={classes.listItem} onClick={() => setModalVisible(true)}>
						<Typography.Text className={classes.itemNumber}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</Typography.Text>
						<Image src={i.image} rootClassName={classes.itemImage} preview={false} />
						<Typography.Text className={classes.itemTitle}>{i.title}</Typography.Text>
					</div>
				</AnimatedComponent>
			))}
			<SendModal open={modalVisible} onCancel={() => setModalVisible(false)} />
		</div>
	);
};

export default SchemeList;
